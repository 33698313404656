








































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { api, Actions as AuditsActions } from "@/store/modules/audits";
import { api as appApi, SetTransitionPanelPayload } from "@/store/modules/app";
import { api as auditApi } from "@/store/modules/audit";
import { DIALOG_NAMES, dialogRoute, ROUTE_NAMES } from "../../../routenames";

import { nullable } from "@auditcloud/shared/lib/types/common";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  AUDIT_ADVANCED_SETTINGS_CONFIG,
  AUDIT_ATTACHMENT_SIDEBAR_CONFIG,
  AUDIT_WORKFLOW_LOG_CONFIG,
} from "@/store/modules/audit/types";

@Component({})
export default class AAuditDataSidebar extends Vue {
  deleting = false;

  @Action(appApi.actions.setTransitionPanel, { namespace: appApi.namespace })
  setTransitionPanel!: (payload: SetTransitionPanelPayload) => Promise<void>;

  @Getter(auditApi.getters.getAuditId, { namespace: auditApi.namespace })
  auditId!: nullable<string>;

  @Getter(auditApi.getters.getAuditName, { namespace: auditApi.namespace })
  auditName!: nullable<string>;

  @Getter(auditApi.getters.getAuditPermissions, {
    namespace: auditApi.namespace,
  })
  auditPermissions!: AuditPermissions;

  @Getter(auditApi.getters.getIsDeleted, {
    namespace: auditApi.namespace,
  })
  isDeleted!: boolean;

  @Action(api.actions.deleteAudit, { namespace: api.namespace })
  deleteAudit!: AuditsActions["deleteAudit"];

  openAuditInfo(val: boolean) {
    const auditId = this.$router.currentRoute.params.auditId;
    this.$router.push({
      name: dialogRoute(
        this.$router.currentRoute.name ?? "error",
        DIALOG_NAMES.AUDIT_INFO_DIALOG
      ),
      params: {
        auditId,
      },
    });
  }

  openAttachments() {
    this.setTransitionPanel(AUDIT_ATTACHMENT_SIDEBAR_CONFIG);
  }

  openAdvancedSettings() {
    this.setTransitionPanel(AUDIT_ADVANCED_SETTINGS_CONFIG);
  }

  openAuditLog() {
    this.setTransitionPanel(AUDIT_WORKFLOW_LOG_CONFIG);
  }

  initAuditDeletion() {
    const auditId = this.auditId;
    if (auditId) {
      if (
        this.auditPermissions.delete &&
        confirm(this.$t("views.audit_matrix.delete_confirm").toString())
      ) {
        this.deleting = true;
        this.deleteAudit({ auditId })
          .then(() =>
            this.$router.push({
              name: ROUTE_NAMES.HOME,
            })
          )
          .finally(() => {
            this.deleting = false;
          });
      }
    }
  }
}
